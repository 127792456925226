<template>
  <v-card outlined v-if="mounted == true">
    <v-card-actions class="pa-0">
      <div class="py-0 pa-3 pb-3 FontSize font-weight-bold">Plant Operation Hour</div>
      <v-spacer></v-spacer>
      <v-select dense outlined class="field_height field_label_size FontSize maxWidth pa-1" :items="plantItems" v-model="current_selection" @change="formLineChartData()"></v-select>
    </v-card-actions>
    <apexchart type="bar" height="240" class="mt-n3" :options="chartOptions" :series="series"></apexchart>
  </v-card>
</template>

<script>
export default {
  props: {
    KPIsArray: Array,
  },
  data: () => ({
    plantItems: ["UF", "RO1", "RO2"],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#008efb", "#00e397"],
      },
      xaxis: {
        categories: ["Jan 21", "Feb 21", "Mar 21", "Apr 21", "May 21", "Jun 21"],
      },

      fill: {
        opacity: 1,
      },
    },
    series: [
      {
        name: "Hours Of Operation",
        type: "bar",
        data: [],
      },
      {
        name: "Downtime",
        type: "bar",
        data: [],
      },
    ],
    current_selection: "UF",
    mounted: true,
  }),
  mounted() {
    this.formLineChartData();
  },
  methods: {
    formLineChartData() {
      this.mounted = false;
      this.$nextTick(() => {
        console.log("Form    Bar Chart", this.KPIsArray);
        this.chartOptions.xaxis.categories = this.KPIsArray.map((month) => `${month.month_text.slice(0, 3)}-${month.kpi_year.toString().slice(2, 4)}`);
        this.series[0].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0
            ? 0
            : this.current_selection == "UF"
            ? month.kpis_array[13] !== undefined
              ? month.kpis_array[13].kpi_value
              : 0
            : this.current_selection == "RO1"
            ? month.kpis_array[5].kpi_value
            : month.kpis_array[9].kpi_value
        );
        this.series[1].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0
            ? 0
            : this.current_selection == "UF"
            ? month.kpis_array[11] !== undefined
              ? month.kpis_array[11].kpi_value
              : 0
            : this.current_selection == "RO1"
            ? month.kpis_array[3].kpi_value
            : month.kpis_array[7].kpi_value
        );
        this.mounted = true;
      });
    },
  },
};
</script>
