<template>
  <div>
    <Overlay :overlay="overlay" />
    <FilterMonthAndYearDialog :FilterYearAndMonthDialog="FilterYearAndMonthDialog" @clicked="FilterYearAndMonthDialogEmit" />
    <v-row class="pa-2" no-gutters>
      <v-col cols="12" md="12" sm="12" xs="12">
        <v-card class="elevation-2 overflow-auto ma-1" height="310px">
          <v-toolbar dense class="py-0 pa-0 ma-0">
            <div class="heading1 PrimaryFontColor">KPI's</div>
            <v-spacer />
            <v-select
              v-model="SelectedHeader"
              outlined
              dense
              multiple
              label="Select Header"
              :items="header"
              item-text="text"
              item-value="value"
              return-object
              class="FontSize field_label_size field_height maxWidth mr-2 pt-7"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" x-small
                  ><span>{{ item.text }}</span></v-chip
                >
                <span v-if="index === 1" class="grey--text">+{{ SelectedHeader.length - 1 }} Others</span>
              </template>
            </v-select>
            <v-btn small class="text-capitalize" color="primary" dark @click="FilterYearAndMonthDialog = true">Select Year And Month</v-btn>
          </v-toolbar>
          <v-data-table
            dense
            :headers="SelectedHeader"
            class="KPITableClass rightSideTree leftSideTree"
            fixed-header
            height="230"
            :items="KPIsArray"
            :items-per-page="12"
            no-data-text="No Data Available"
            hide-default-footer
          >
            <template v-slot:[`item.kpi_year`]="{ item }">
              <div class="fontSize">
                {{ item.kpi_year ? item.kpi_year : "-" }}
              </div>
            </template>
            <template v-slot:[`item.month_text`]="{ item }">
              <div class="fontSize">
                {{ item.month_text ? item.month_text : "-" }}
              </div>
            </template>
            <template v-slot:[`item.uffl_water_inlet`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "uffl_water_inlet") !== "-" ? GetKPIValueMethod(item, "uffl_water_inlet").toFixed(2) : "-") : "-" }}
                <!-- {{ item.kpis_array.length != 0 && item.kpis_array[16] !== undefined ? item.kpis_array[16].kpi_value.toFixed(2) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.uffl_percentage_of_rated_capacity`]="{ item }">
              <div class="fontSize">
                {{
                  item.kpis_array.length !== 0
                    ? GetKPIValueMethod(item, "uffl_percentage_of_rated_capacity") !== "-"
                      ? GetKPIValueMethod(item, "uffl_percentage_of_rated_capacity").toFixed(2)
                      : "-"
                    : "-"
                }}

                <!-- {{ item.kpis_array.length != 0 && item.kpis_array[15] !== undefined ? item.kpis_array[15].kpi_value.toFixed(2) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.uf_water_produced`]="{ item }">
              <div class="fontSize" v-if="item.kpis_array.length != 0">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "uf_water_produced") !== "-" ? GetKPIValueMethod(item, "uf_water_produced").toFixed(2) : "-") : "-" }}
                <!-- {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "uf_water_produced") !== "-" ? GetKPIValueMethod(item, "uf_water_produced").toFixed(2) : "-" : "-" }} -->

                <!-- {{
                  item.kpis_array.length != 0
                    ? typeof item.kpis_array[14].kpi_value == "number"
                      ? item.kpis_array[14] !== undefined
                        ? item.kpis_array[14].kpi_value.toFixed(2)
                        : 0
                      : item.kpis_array[14].kpi_value !== undefined
                      ? item.kpis_array[14].kpi_value
                      : 0
                    : "-"
                }} -->
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.uf_rated_capacity`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "uf_rated_capacity") !== "-" ? GetKPIValueMethod(item, "uf_rated_capacity").toFixed(2) : "-") : "-" }}

                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[12] !== undefined ? item.kpis_array[12].kpi_value.toFixed(2) : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.ro1_water_produced`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "ro1_water_produced") !== "-" ? GetKPIValueMethod(item, "ro1_water_produced").toFixed(2) : "-") : "-" }}

                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[6] !== undefined ? item.kpis_array[6].kpi_value.toFixed(2) : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.ro1_rated_capacity`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "ro1_rated_capacity") !== "-" ? GetKPIValueMethod(item, "ro1_rated_capacity").toFixed(2) : "-") : "-" }}

                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[4] !== undefined ? item.kpis_array[4].kpi_value.toFixed(2) : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.ro2_water_produced`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "ro2_water_produced") !== "-" ? GetKPIValueMethod(item, "ro2_water_produced").toFixed(2) : "-") : "-" }}

                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[10] !== undefined ? item.kpis_array[10].kpi_value.toFixed(2) : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.ro2_rated_capacity`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "ro2_rated_capacity") !== "-" ? GetKPIValueMethod(item, "ro2_rated_capacity").toFixed(2) : "-") : "-" }}
                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[8] !== undefined ? item.kpis_array[8].kpi_value.toFixed(2) : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.overall_plant_recovery`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "overall_plant_recovery") : "-" }}
                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[1] !== undefined ? item.kpis_array[1].kpi_value : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.previous_day_plant_recovery`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "previous_day_plant_recovery") : "-" }}
                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[2] !== undefined ? item.kpis_array[2].kpi_value : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.dmf_backwash_and_rinse`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? (GetKPIValueMethod(item, "dmf_backwash_and_rinse") !== "-" ? GetKPIValueMethod(item, "dmf_backwash_and_rinse").toFixed(2) : "-") : "-" }}

                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[0] !== undefined ? item.kpis_array[0].kpi_value.toFixed(2) : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.uf_total_hours_of_operation`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "uf_total_hours_of_operation") : "-" }}
                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[13] !== undefined ? item.kpis_array[13].kpi_value : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.uf_down_time`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "uf_down_time") : "-" }}
                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[11] !== undefined ? item.kpis_array[11].kpi_value : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.ro1_total_hours_of_operation`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "ro1_total_hours_of_operation") : "-" }}
                <!-- {{ item.kpis_array.length !== 0 ? item.kpis_array.find((kpi) => kpi.kpi_type === "ro1_total_hours_of_operation")?.kpi_value || "-" : "-" }} -->
                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[5] !== undefined ? item.kpis_array[5].kpi_value : 0) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.ro1_down_time`]="{ item }">
              <div class="fontSize">
                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[3] !== undefined ? item.kpis_array[3].kpi_value : 0) : "-" }} -->
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "ro1_down_time") : "-" }}
              </div>
            </template>
            <template v-slot:[`item.ro2_total_hours_of_operation`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "ro2_total_hours_of_operation") : "-" }}
                <!-- {{ item.kpis_array.length != 0 ? (typeof item.kpis_array[9].kpi_value == "number" ? item.kpis_array[9].kpi_value : item.kpis_array[9].kpi_value) : "-" }} -->
              </div>
            </template>
            <template v-slot:[`item.ro2_down_time`]="{ item }">
              <div class="fontSize">
                {{ item.kpis_array.length !== 0 ? GetKPIValueMethod(item, "ro2_down_time") : "-" }}

                <!-- {{ item.kpis_array.length != 0 ? (item.kpis_array[7] !== undefined ? item.kpis_array[7].kpi_value : 0) : "-" }} -->
              </div>
            </template>
            <template v-for="(h, i) in header" v-slot:[`header.${h.value}`]="{ header }">
              <div :key="i">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>{{ header.tooltip_text }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12" xs="12">
        <v-card class="elevation-2 overflow-hidden ma-1 leftSideTree" height="300px">
          <LineChart :KPIsArray="KPIsArray" v-if="RenderComponent" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12" xs="12" v-if="KPIsArray.length != 0">
        <v-card class="elevation-2 overflow-hidden ma-1 leftSideTree" height="300px" v-if="KPIsArray.length != 0">
          <BarChart :KPIsArray="KPIsArray" v-if="RenderComponent" />
        </v-card>
      </v-col>
      <v-col cols="12" md="12" sm="12" xs="12" v-if="KPIsArray.length != 0">
        <v-card class="elevation-2 overflow-auto overflow-hidden ma-1 leftSideTree" height="300px">
          <GroupBarChart />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { GetKPIs } from "@/mixins/GetKPIs.js";
import Overlay from "@/components/Extras/Overlay.vue";
import FilterMonthAndYearDialog from "@/components/FilterMonthAndYear/FilterMonthAndYearDialog.vue";
import BarChart from "@/views/ChildHomePages/LandingPages/Charts/BarChart.vue";
import LineChart from "@/views/ChildHomePages/LandingPages/Charts/LineChart.vue";
import GroupBarChart from "@/views/ChildHomePages/LandingPages/Charts/GroupBarChart.vue";
export default {
  components: {
    BarChart,
    GroupBarChart,
    LineChart,
    Overlay,
    FilterMonthAndYearDialog,
  },
  mixins: [GetKPIs],
  data: () => ({
    SelectedHeader: [],
    FilterYearAndMonthDialog: false,
    header: [
      {
        text: "Year",
        show_table: true,
        value: "kpi_year",
        sortable: false,
        align: "center",
        disabled: true,
      },
      {
        text: "Month",
        value: "month_text",
        show_table: true,
        sortable: false,
        align: "center",
        disabled: true,
      },
      {
        text: "#MPWI",
        show_table: true,
        sortable: false,
        align: "center",
        value: "uffl_water_inlet",
        tooltip_text: "Monthly Plant Water Inlet (M³)",
      },
      {
        text: "#WIR",
        show_table: true,
        sortable: false,
        align: "center",
        value: "uffl_percentage_of_rated_capacity",
        tooltip_text: "Water Inlet % Of Rated Water Inlet",
      },
      {
        text: "#MWPUF",
        value: "uf_water_produced",
        show_table: true,
        sortable: false,
        align: "center",
        tooltip_text: "Monthly Water Produced by UF (M³)",
      },
      {
        text: "#WPRC",
        value: "uf_rated_capacity",
        show_table: true,
        sortable: false,
        align: "center",
        tooltip_text: "UF %  Water Produced Of Rated Capacity",
      },
      {
        text: "#MWPRO1",
        show_table: true,
        sortable: false,
        value: "ro1_water_produced",
        align: "center",
        tooltip_text: "Monthly Water Produced by RO1(M³)",
      },
      {
        text: "#RO1WPRC",
        show_table: true,
        sortable: false,
        value: "ro1_rated_capacity",
        align: "center",
        tooltip_text: "RO1 % of Water produced of Rated Capacity",
      },
      {
        text: "#MWPRO2",
        sortable: false,
        show_table: true,
        value: "ro2_water_produced",
        align: "center",
        tooltip_text: '"Monthly Water Produced by RO2(M³)',
      },
      {
        text: "#RO2WP",
        sortable: false,
        show_table: true,
        value: "ro2_rated_capacity",
        align: "center",
        tooltip_text: "RO2 % of water produced of Rated Capacity",
      },
      // {
      //   text: "Overall Plant Recovery",
      //   show_table: false,
      //   sortable: false,
      //   value: "overall_plant_recovery",
      // },
      // {
      //   text: "Previous Day Plant Recovery",
      //   sortable: false,
      //   show_table: false,
      //   align: "center",
      //   value: "previous_day_plant_recovery",
      // },
      {
        text: "#DMF",
        sortable: false,
        show_table: false,
        align: "center",
        value: "dmf_backwash_and_rinse",
        tooltip_text: "DMF Backwash & Rinse",
      },
      {
        text: "#UFHO",
        sortable: false,
        show_table: true,
        value: "uf_total_hours_of_operation",
        align: "center",
        tooltip_text: "UF Hrs of Operation",
      },
      {
        text: "#UFD",
        sortable: false,
        show_table: true,
        value: "uf_down_time",
        align: "center",
        tooltip_text: "UF Downtime",
      },
      {
        text: "#RO1HO",
        sortable: false,
        show_table: true,
        value: "ro1_total_hours_of_operation",
        align: "center",
        tooltip_text: "RO1 Hrs of Operation",
      },
      {
        text: "#RO1D",
        sortable: false,
        show_table: true,
        value: "ro1_down_time",
        align: "center",
        tooltip_text: "RO1 Downtime",
      },
      {
        text: "#RO2HO",
        sortable: false,
        show_table: true,
        value: "ro2_total_hours_of_operation",
        align: "center",
        tooltip_text: "RO2 Hrs of Operation",
      },
      {
        text: "#RO2D",
        sortable: false,
        show_table: true,
        value: "ro2_down_time",
        align: "center",
        tooltip_text: "RO2 Downtime",
      },
    ],

    items: [],
    FourthTable: [
      {
        text: "MTD",
        value: "",
      },
      {
        text: "ll",
        value: "",
      },
    ],

    GetYear: [],
    GetMonth: [
      { text: "January", value: 1 },
      { text: "Feburary", value: 2 },
      { text: "March", value: 3 },
      { text: "April", value: 4 },
      { text: "May", value: 5 },
      { text: "June", value: 6 },
      { text: "July", value: 7 },
      { text: "August", value: 8 },
      { text: "September", value: 9 },
      { text: "October", value: 10 },
      { text: "November", value: 11 },
      { text: "December", value: 12 },
    ],
    YearVal: 0,
    MonthVal: "",
    menu: false,
    date: new Date().toISOString().substr(0, 7),
    KPIsArray: [],
    RenderComponent: true,
  }),

  mounted() {
    this.SelectedHeader = this.header.filter((item) => item.show_table == true);

    this.getDateForKPI();
  },
  methods: {
    async getDateForKPI() {
      this.KPIsArray = await this.GetKPIsMethod(
        this.GetMonth.map((date) => {
          let dateObject = {
            year: new Date().getFullYear(),
            month: date.value,
          };
          return dateObject;
        })
      );
    },
    FilterYearAndMonthDialogEmit(Toggle, response_array) {
      this.FilterYearAndMonthDialog = false;
      if (Toggle == 2) {
        this.KPIsArray = response_array;
        this.RenderComponent = false;
        this.$nextTick(() => {
          this.RenderComponent = true;
        });
      }
    },
    GetKPIValueMethod(item, kpi_value) {
      let findValue = item.kpis_array.find((kpi) => kpi.kpi_type === kpi_value);

      if (findValue) {
        return findValue.kpi_value;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
th,
td {
  border: 1px solid;
  column-width: 900px;
}
</style>
